@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap");

html,
body,
#root {
  height: 100%;
  margin: 0;
  overflow: hidden; /* Prevent the whole page from scrolling */
}
.content {
  flex-grow: 1;
  overflow-x: auto;
  height: 100vh;
}

.app {
  display: flex;
  position: relative;
  min-height: 100vh;
  font-family: "Source Sans 3", sans-serif;
}

.sidebar {
  flex-shrink: 0;
  height: 100vh; /* Full height */
  overflow-y: auto; /* Enable scrolling within the sidebar if needed */
  position: fixed; /* Fix the sidebar position */
}

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #141b2d;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #3da58a;
  border-radius: 5px;
}

/* Handle on Hover*/

::-webkit-scrollbar-track:hover {
  background: #1F2A40;
}

/* Define the animation */
@keyframes ring {
  0% {
    box-shadow: 0 0 0 0px var(--ring-color, transparent);
  }
  100% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
  }
}

/* Apply the animation to a class */
.avatar-ring {
  display: inline-flex; /* Keeps the Box inline and centered */
  border-radius: 50%; /* Ensures the animated element is circular */
  animation: ring 1.5s infinite; /* Applies the animation */
}
